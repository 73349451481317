import React, { useRef, useState, useEffect } from "react";
import {
  Row,
  Col,
  Typography,Button
} from "antd";
import { ReactReader, ReactReaderStyle } from "react-reader";
import { LoadSingleBatchAction } from "../../../redux/actions/SingleBatchAction";
import { useDispatch, useSelector } from "react-redux";
import { LoadProfileAction } from "../../../redux/actions/ProfileActions";
import { useHistory } from "react-router-dom";

import {
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

// import "antd/dist/antd.css";
import 'antd/dist/antd.min.css';
const { Title } = Typography;

const App = ({ match }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    
dispatch(LoadSingleBatchAction(match.params.batch_id));
    return () => {};
  }, []);
useSelector((state) => state.userDetails.userProfile);

let history = useHistory();

  useEffect(() => {
    dispatch(LoadProfileAction());

    return () => {};
  }, []);
  const batchResponse = useSelector(
    (state) => state.userSingleBatch.userSingleBatch
  );

  const [selections, setSelections] = useState([]);
  const renditionRef = useRef(null);
  const [page, setPage] = useState("");
  const [location, setLocation] = useState(null);
  const tocRef = useRef(null);
  const locationChanged = (epubcifi) => {
    setLocation(epubcifi);
    if (renditionRef.current && tocRef.current) {
      const { displayed, href } = renditionRef.current.location.start;
      const chapter = tocRef.current.find((item) => item.href === href);
      setPage(
        `Page ${displayed.page} of ${displayed.total} in chapter ${
          chapter ? chapter.label : "n/a"
        }`
      );
    }
  };
  
  const [size, setSize] = useState(100);
  const changeSize = (newSize) => {
    setSize(newSize);
  };

  //For font size
  useEffect(() => {
    if (renditionRef.current) {
      renditionRef.current.themes.fontSize(`${size}%`);
    }
  }, [size]);

  //For selected data to display & adding css to text
  useEffect(() => {
    if (renditionRef.current) {
      renditionRef.current.themes.fontSize(`${size}%`);
      function setRenderSelection(cfiRange, contents) {
        setSelections(
          selections.concat({
            text: renditionRef.current.getRange(cfiRange).toString(),
            cfiRange,
          })
        );
        renditionRef.current.annotations.add(
          "highlight",
          cfiRange,
          {},
          null,
          "hl",
          { fill: "red", "fill-opacity": "0.5", "mix-blend-mode": "multiply" }
        );
        contents.window.getSelection().removeAllRanges();
      }
      renditionRef.current.on("selected", setRenderSelection);

      return () => {
        renditionRef.current.off("selected", setRenderSelection);
      };
    }
  }, [setSelections, selections]);

  const ownStyles = {
    ...ReactReaderStyle,
    arrow: {
      ...ReactReaderStyle.arrow,
      color: "#000000",
    },
  };

  return (
    <>
      {batchResponse !== "" &&
      batchResponse !== null &&
      batchResponse.success === true ? (
        <div  style={{padding:"20px"}} >
          <Button className="bookread_btn" onClick={() => history.goBack()}>Back</Button>


          {batchResponse.singlebatch.map((batch) => (
            <Row gutter="32">
              {batch.resources.map((resource) => (
                <Col span="24" style={{ margin: "10px;" }}>
              
                  {resource.id == match.params.book_id ? (
                    <div>
                 
                      <Col span="24">
                        <Title level={3} style={{ color: "#f6921e" }}>
                          {resource.name}{" "}
                        </Title>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: resource.description,
                          }}
                        />
                      </Col>

                      {resource.media_files.map((media) => (
                        <div>
                          {/* {media.media_path} */}
                          <div
                            style={{ position: "relative", height: "80vh" }}
                            className="myReader"
                          >
                        
                            <ReactReader
                              location={location}
                              locationChanged={locationChanged}
                              url={media.media_path}
                              getRendition={(rendition) => {
                                renditionRef.current = rendition;
                                renditionRef.current.themes.fontSize(
                                  `${size}%`
                                );
                                renditionRef.current.themes.default({
                                  "::selection": {
                                    background: "orange",
                                  },
                                });
                                setSelections([]);
                              }}
                              tocChanged={(toc) => (tocRef.current = toc)}
                              title={page}
                              styles={ownStyles}
                            />
                          </div>
                          <div
                            style={{
                              position: "relative",
                              bottom: "1rem",
                              right: "1rem",
                              left: "1rem",
                              textAlign: "center",
                              zIndex: 1,
                            }}
                          >
                            <MinusCircleOutlined
                              style={{ color: "#5e92ec" }}
                              onClick={() =>
                                changeSize(Math.max(80, size - 10))
                              }
                            />
                            <span>&nbsp;Current size: {size}%&nbsp;</span>
                            <PlusCircleOutlined
                              style={{ color: "#5e92ec" }}
                              onClick={() =>
                                changeSize(Math.min(130, size + 10))
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Col>
              ))}
              <Col span="6"></Col>
            </Row>
          ))}
        </div>
      ) : (
        <div>Unable to display Batch Details</div>
      )}

      {/* <b style={{ position: 'relative', bottom: '1rem', right: '1rem', zIndex: 1, backgroundColor: 'white'}}>Selected Text:</b> */}
      {/* {selections.length != 0 ? 
        <div style={{ position: 'relative', bottom: '1rem', right: '1rem', zIndex: 1, backgroundColor: 'white'}}>
        <SelectOutlined style={{color: '#3c9d2a'}}/> Click to Show, <CloseSquareOutlined style={{color: '#ea6865'}}/> Click to Remove.<br/>
        <ul>
          {selections.map(({text, cfiRange}, i) => (
            <li key={i}>
              {text} <SelectOutlined style={{color: '#3c9d2a',fontSize: '18px'}} onClick={() => {
                renditionRef.current.display(cfiRange)
              }}/>&nbsp;
              <CloseSquareOutlined style={{color: '#ea6865',fontSize: '18px'}} onClick={() => {
                renditionRef.current.annotations.remove(cfiRange, 'highlight')
                setSelections(selections.filter((item, j) => j !== i))
              }}/>
            </li>
          ))}
        </ul>
      </div>
      :''} */}
    </>
  );
};
export default App;
