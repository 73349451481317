import * as ActionTypes from "../ActionTypes";
const initState = {
  userBatches: "",
};
const BatchesReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return {
        ...state,
        userBatches: "loading...",
      };
    case ActionTypes.LOAD_BATCHES_SUCCESS:
      return {
        ...state,
        userBatches: action.res,
      };
    case ActionTypes.LOAD_BATCHES_ERROR:
      return {
        ...state,
        userBatches: action.res,
      };
    case ActionTypes.CODE_ERROR:
      return {
        ...state,
        userBatches: "There seems to be a problem, please refresh your browser",
      };
    default:
      return state;
  }
};
export default BatchesReducer;
