import React from "react";
import {  Row, Col, Typography } from "antd";
const { Title } = Typography;
function DashboardComponent() {
  return (
    <div  style={{
      padding: "20px",
    }}>
      <Row>
        <Col span="24" className="textalign_center"><Title level={2}>Welcome to ReadMark</Title><p>In a study, researchers found that approximately the third graders with below basic reading skills did not graduate their high school, at the age of 19. Moreover, third graders who are proficient in reading are more likely to graduate from high school compared to below basic readers, at the age of 19.</p></Col>
        </Row>
    </div>
  );
}
export default DashboardComponent;
