import HttpService from "./HttpService";
export const LoadSingleBatch = (data) => {
  const http = new HttpService();
  // console.log(data.id);
  let SinglebatchesUrl = `users/batches/${data.id}`;
  const tokenId = "user-token";
  return http
    .getData(SinglebatchesUrl, tokenId)
    .then((data) => {
      // console.log(data);
      return data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};
