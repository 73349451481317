import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Row, Col, Typography } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RegisterAction } from "../../redux/actions/AuthActions";

const { Title } = Typography;

function RegisterComponent() {
  const dispatch = useDispatch();
  //const authResponse = useSelector(state=>state.userAuth.authResponse);
  const [fields, setState] = useState({
    name: "",
    phone: "000000000",
    email: "",
    password: "",
    center_id: 9,
    batch_id: [],
    center_id: 9,
    course_id: [17],
    role_id: 76,
    student_mode: "enquiry",
    orgId: 2,
    status: false,
    access: true,
    live_access: false,
    password_confirmation: "",
  });
  const handleFieldChange = (e) => {
    setState({
      ...fields,
      [e.target.id]: e.target.value,
    });
  };
  const UserRegister = (e) => {
    // e.preventDefault();
    console.log(fields);
    dispatch(RegisterAction(fields));

    // const passwordMatch = checkPasswordMatch(fields.password, fields.password_confirmation);
    // if(passwordMatch === true){
    // alert('passwords dont match. please check your password again');
    // return;
    // }
    // dispatch(RegisterAction(fields));
  };
  const checkPasswordMatch = (password, password_confirmation) => {
    return password !== password_confirmation ? true : false;
  };
  return (
    <Row>
      <Col span="9"></Col>
      <Col span="6" className="textalign_center">
        <br></br>
        <br></br>

        <Title level={3}>Register to Your Account</Title>
        <p>Welcome to Abhyaas ReadMark</p>
        <Form
          className="login-form"
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          onFinish={UserRegister}
        >
          <Form.Item
            name="name"
            label="Name"
            onChange={handleFieldChange}
            rules={[
              {
                required: true,
                message: "Please input your Name!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Your Name"
              id="name"
              value={fields.name}
            />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            onChange={handleFieldChange}
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
              id="email"
              value={fields.email}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            required
            onChange={handleFieldChange}
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              id="password"
              value={fields.password}
            />
          </Form.Item>
         

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              block
            >
              Log in
            </Button>
            <br></br>
            <br></br>
            Already have an account? <Link to="/user/login">Login Here</Link>
            <br></br>
            <Link to="/home">Back To Home Page </Link>
          </Form.Item>
        </Form>
      </Col>
      <Col span="9">
        <div></div>

        <div></div>
      </Col>
    </Row>
  );
}

export default RegisterComponent;
