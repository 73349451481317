import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Row,
  Col,
  Avatar,
  Dropdown,
  Space,
  Spin
} from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  DownOutlined,
  SmileOutlined,
  DashboardFilled,
  DatabaseOutlined,
  UserOutlined
} from "@ant-design/icons";

import { Switch, Route, Redirect } from "react-router-dom";
// import Home from "./components/pages/HomeComponent";
import Login from "./components/pages/LoginComponent";
// import AboutUs from "./components/pages/AboutUsComponent";
// import Contact from "./components/pages/ContactComponent";
import Register from "./components/pages/RegisterComponent";
// import News from "./components/pages/NewsComponent";
import UserLogin from "./components/pages/UserLoginComponent";
import logo from "./assets/logo.png";

import PrivateRoute from "./PrivateRoute";
import { Guard } from "./Guards";
import { Button } from "@material-ui/core";

// import { useStyles } from "./styles/styles";
import { LogoutAction } from "./redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory,Link } from "react-router-dom";
import { LoadProfileAction } from "./redux/actions/ProfileActions";

function Routes() {
  const [current, setCurrent] = useState("dashboard");

  const onClick = (e) => {
    console.log("click ", e);

    console.log(e.key);
    if (e.key === "user/courses") {
      history.push("/user/courses");
    }
    if (e.key === "user/dashboard") {
      history.push("/user/dashboard");
    }
    if (e.key === "user/books") {
      history.push("/user/books");
    }
    setCurrent(e.key);
  };

  const history = useHistory();

  var sidebar = false;
  // console.log(history.location.pathname);

  if (
    history.location.pathname === "/home" ||
    history.location.pathname === "/" ||
    history.location.pathname === "/aboutus" ||
    history.location.pathname === "/contact" || history.location.pathname === "/news-corner"
  ) {
    // sidebar = 1;
    sidebar = false;
    // console.log("disable Side bar");
  } else {
    sidebar = true;
    // console.log("enable Side bar");
  }

  // const classes = useStyles();
  const dispatch = useDispatch();
  const authResponse = useSelector((state) => state.userAuth.authResponse);
  const profileResponse = useSelector((state) => state.userDetails.userProfile);
  const logOut = () => {
    dispatch(LogoutAction());
    history.push("/user/login");
  };
  const login = () => {
    history.push("/user/login");
  };
  const token = localStorage.getItem("user-token");

  if (token !== null && token !== "") {
    if (
      history.location.pathname === "/user/login" ||
      history.location.pathname === "/user/register" 
    ) {
      history.push("/user/dashboard");
    }
  }

  useEffect(() => {
    if (authResponse !== "" && authResponse.success === true) {
      // alert(authResponse.message);
      // console.log(localStorage);
      if (authResponse.success === true) {
        localStorage.removeItem("user-token");
      }
    }
    return () => {};
  }, [authResponse]);

  const { Header, Content, Sider, Footer } = Layout;

  useEffect(() => {
    dispatch(LoadProfileAction());

    return () => {};
  }, []);
  const menuitems = [
    {
      label: <Link to="/user/dashboard" alt="Dashboard"> Dashboard</Link>,
      key: "user/dashboard",
      icon: <DashboardFilled />,
    },
    {
      label: <Link to="/user/courses"> Courses</Link>,
      key: "user/courses",
      icon: <DatabaseOutlined />,
    },
  
  ];
  const [collapsed, setCollapsed] = useState(false);
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: <Link  to="/user/view-profile" > Profile </Link>,
          icon: <SmileOutlined />,
        },
        {
          key: "2",
          label: <Link  to="/user/dashboard"> Dashboard </Link>,
          icon: <SmileOutlined />,
        },
        {
          key: "3",
          label: (
            <Link to="_blank"
              rel="noopener noreferrer"
              href="https://www.luohanacademy.com"
            >
              Support
            </Link>
          ),
        },
        {
          key: "4",
          label: (
            <span alt="logout" color="inherit" onClick={logOut} >
              Logout
            </span>
          ),
          icon: <LogoutOutlined />,
        },
      ]}
    />
  );

  return (
    <>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Header className="header shadow">
          <Row>
            <Col xs={6} sm={6} md={6} lg={3}>
              <Link to="/home">
                {" "}
                <img src={logo} width="80px" height="auto" alt="logo"></img>
              </Link>
            </Col>
            <Col xs={10} sm={10} md={10} lg={17}>
              <Row>
                <Col span="2">
               
                  {token !== null && token !== "" && sidebar === true ? (
                    <div>
                      {" "}
                      {React.createElement(
                        collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                        {
                          className: "trigger",
                          onClick: () => setCollapsed(!collapsed),
                        }
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Col>
                <Col span="22">
                  {/* <Menu mode="horizontal">
                    <Menu.Item key="mail1">
                      {" "}
                      <a href="/aboutus"> About Us </a>{" "}
                    </Menu.Item>
                    <Menu.Item key="mail2">
                      {" "}
                      <a href="/contact"> Conatct </a>{" "}
                    </Menu.Item>
                    <Menu.SubMenu key="SubMenu" title="Resources">
                      <Menu.Item key="two" icon={<AppstoreOutlined />}>
                        News Corner
                      </Menu.Item>
                      <Menu.Item key="three" icon={<AppstoreOutlined />}>
                        Audio Corner
                      </Menu.Item>
                      <Menu.Item key="four" icon={<AppstoreOutlined />}>
                        Daily Prep Corner
                      </Menu.Item>
                      <Menu.Item key="five" icon={<AppstoreOutlined />}>
                        Book Corner
                      </Menu.Item>
                    </Menu.SubMenu>
                  </Menu> */}
                </Col>
              </Row>
            </Col>
            <Col xs={8} sm={8} md={8} lg={4} className="textalign">
              {token !== null && token !== "" ? (
                <div>
                  <Dropdown overlay={menu}>
                    <span onClick={(e) => e.preventDefault()} alt="downlad">
                      <Space>
                        <Avatar  style={{ backgroundColor: '#f6921e' }} icon={<UserOutlined />} ></Avatar> <DownOutlined />
                      </Space>
                    </span>
                  </Dropdown>
                </div>
              ) : (
                <Button color="inherit" onClick={login}>
                  Login
                </Button>
              )}
            </Col>
          </Row>
        </Header>

        <Layout>
          {token !== null && token !== "" && sidebar === true ? (
            <Sider
              width={200}
              className="site-layout-background"
              breakpoint="lg"
              trigger={null}
              collapsible
              collapsed={collapsed}
              collapsedWidth="0"
              onBreakpoint={(broken) => {
                // console.log(broken);
              }}
              onCollapse={(collapsed, type) => {
                // console.log(collapsed, type);
              }}
            >
              <Row className="profile_avatar">
                <Col span="24">
                  {profileResponse !== "" &&
                  profileResponse !== null &&
                  profileResponse.success === true ? (
                    <div><br></br>
                      <Avatar
                        size={80}
                        style={{ backgroundColor: '#f6921e' }} icon={<UserOutlined />} 
                      />
                      <br></br>
                      <b> {profileResponse.data.fullName}</b>
                      <p> {profileResponse.data.student_code}</p>
                    </div>
                  ) : (
                    <div className="spin_pageload">
                    <Spin />
                  </div>
                  )}
                </Col>
              </Row>

              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                items={menuitems}
              />
            </Sider>
          ) : (
            <div></div>
          )}
          <Layout>
            <Content
              className="site-layout-background"
              style={{
                margin: 0,
                minHeight: 280,
              }}
            >
              <div></div>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => <Redirect to={{ pathname: "/home" }} />}
                />

                <Route path="/home" component={Login} />

                <Route path="/user/login" component={Login} />
                <Route path="/user/register" component={Register} />
                {/* <Route path="/aboutus" component={AboutUs} />
                <Route path="/contact" component={Contact} />
                <Route path="/news-corner" component={News} /> */}
                <Route path="/user_login" component={UserLogin} />
                

               
                <Guard
                  path="/user"
                  token="user-token"
                  routeRedirect="/user/login"
                  component={PrivateRoute}
                />
              </Switch>
            </Content>
            <Footer
              style={{
                textAlign: "center",
              }}
            >
              ReadMark ©2022 Created by Abhyaas Tech Labs 
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
}

export default Routes;
