import * as ActionTypes from "../ActionTypes";
const initState = {
  userSingleBatch: "",
};
const SingleBatchReducer = (state = initState, action) => {
  switch (action.type) {
    case ActionTypes.LOADING:
      return {
        ...state,
        userSingleBatch: "loading...",
      };
    case ActionTypes.LOAD_SINGLEBATCH_SUCCESS:
      return {
        ...state,
        userSingleBatch: action.res,
      };
    case ActionTypes.LOAD_SINGLEBATCH_ERROR:
      return {
        ...state,
        userSingleBatch: action.res,
      };
    case ActionTypes.CODE_ERROR:
      return {
        ...state,
        userSingleBatch:
          "There seems to be a problem, please refresh your browser",
      };
    default:
      return state;
  }
};
export default SingleBatchReducer;
