import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Col, Typography } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LoginAction } from "../../redux/actions/AuthActions";
import { useHistory, Link } from "react-router-dom";

const { Title } = Typography;

function LoginComponent() {
  const history = useHistory();
  //console.log(history);
  const dispatch = useDispatch();
  //const authResponse = useSelector(state=>state.userAuth.authResponse);
  const [fields, setState] = useState({
    email: "",
    password: "",
    token: null
  });
  const handleFieldChange = (e) => {
    setState({
      ...fields,
      [e.target.id]: e.target.value,
    });
  };
  const token = localStorage.getItem("user-token");

  if (token !== null && token !== "") {
   
    history.push("/user/dashboard");
  }
  const UserLogin = (e) => {
    // alert(e);
    // e.preventDefault();
    console.log(fields);
    dispatch(LoginAction(fields, history));
    console.log(history);
  };
  return (
    <Row>
      <Col xs={2} sm={4} md={6} lg={9}></Col>
      <Col xs={20} sm={20} md={20} lg={6} className="textalign_center">
        <br></br>
        <br></br>

        <Title level={3}>Login to Your Account</Title>
        <p>Welcome to Abhyaas ReadMark</p>
        <Form
          className="login-form"
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          onFinish={UserLogin}
        >
          <Form.Item
            name="email"
            label="Email/UserName"
            onChange={handleFieldChange}
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              id="email"
              value={fields.email}
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            required
            onChange={handleFieldChange}
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              id="password"
              value={fields.password}
            />
          </Form.Item>
        

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              block
            >
              Log in
            </Button>
            <br></br>
            <br></br>
            Don't Have an Account <Link to="/user/register">Register Here</Link>
            <br></br>
            <Link to="/home">Back To Home Page </Link>
          </Form.Item>
        </Form>
      </Col>
      <Col xs={2} sm={4} md={6} lg={9}>
        <div></div>

        <div></div>
      </Col>
    </Row>
  );
}

export default LoginComponent;
