import React, { useEffect } from "react";
import { Card, Row, Col, Button, Typography, Spin } from "antd";
import { Link } from "react-router-dom";
import { LoadProfileAction } from "../../../redux/actions/ProfileActions";

import { useDispatch, useSelector } from "react-redux";
import { LoadBatchesAction } from "../../../redux/actions/BatchesAction";
import { useHistory } from "react-router-dom";

const { Title } = Typography;
const { Meta } = Card;

export default function CoursesComponent() {
  const dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    const batch = dispatch(LoadBatchesAction());
    dispatch(LoadProfileAction());

    return () => {};
  }, []);
  const batchResponse = useSelector((state) => state.userBatches.userBatches);

  return (
    <div
      style={{
        padding: "20px",
      }}
    >
        <Button className="bookread_btn" onClick={() => history.goBack()}>Back</Button>
      {batchResponse !== "" &&
      batchResponse !== null &&
      batchResponse.success === true ? (
        <div>
          <Row>
            <Col xs={24} sm={24} md={24} lg={12}>
           <Title level={3} style={{ color: "#f6921e" }}>
           Your Reading Levels
                </Title>
          
          <p>A study found that reading books reduces stress by 68% when compared to listening to music, walking or having a cup of coffee.</p>
          </Col>
          </Row>
        
           

          {batchResponse.enrolled_batches.length === 0?<div>{batchResponse.message}</div>:
          
          <div>
            
            {batchResponse.enrolled_batches.map((batch) => (
              
              <Col xs={24} sm={24} md={24} lg={6}>
                <Card
                  hoverable
                  style={{
                    width: "95%",
                  }}
                  cover={
                    <img alt="Course_Image" max-height={"250px"} src={batch.image} />
                  }
                >
                  <div>
                  <Title level={5}>{batch.name}</Title>
                 
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: batch.description.substring(0, 150) }}
                  />
                  <Button
                    className="bookread_btn"
                  >
                    {" "}
                    <Link
                 className="bookread_btn"
                    to={`/user/course/${batch.id}`}
                  >View Course</Link>
                 </Button>

                  {/* <Button className='primary_button'>Read More</Button> */}
                </Card>
              </Col>
            ))}

                       </div>}


     
        </div>
      ) : (
        <div className="spin_pageload">
          <Spin />
        </div>
      )}
    </div>
  );
}
