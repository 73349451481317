import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import ProfileReducer from "./ProfileReducer";
import BatchesReducer from "./BatchesReducer";
import ExamResultsReducer from "./ExamResultsReducer";
import SingleBatchReducer from "./SingleBatchReducer";

const RootReducer = combineReducers({
  userAuth: AuthReducer,
  userDetails: ProfileReducer,
  userBatches: BatchesReducer,
  userSingleBatch: SingleBatchReducer,
  examResults:ExamResultsReducer
});
export default RootReducer;
