import React, {useEffect} from "react";
import {  Typography, Spin,Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadExamResultsAction } from "../../../redux/actions/ExamResultsAction";
import { Tabs } from 'antd';
import { LoadProfileAction } from "../../../redux/actions/ProfileActions";
import { useHistory } from "react-router-dom";

import { Table } from 'antd';
const { TabPane } = Tabs;



const ExamResultsComponent = ({ match }) => {
  let history = useHistory();
    const { Title } = Typography;

    const columns = [
        {
            title: '#   ',
            dataIndex: 's_no',
            key: 's_no',
          },
        {
          title: 'Question Title',
          dataIndex: 'question_title',
          key: 'question_title',
          width: 250,
          render: function(html) { 
            return <div dangerouslySetInnerHTML={{__html: html}} />;
        },
        },
        {
          title: 'Level',
          dataIndex: 'level',
          key: 'level',
        },
        {
            title: 'Answers',
            dataIndex: 'answer',
            key: 'answer',
          },
          {
            title: 'Submitted',
            dataIndex: 'answer_submitted',
            key: '  ',
          },
          {
            title: 'Time',
            dataIndex: 'time',
            key: 'time',
          },
          {
            title: 'Marks',
            dataIndex: 'marks',
            key: 'marks',
          },
          {
            title: 'Review',
            dataIndex: 'review',
            key: 'review',
          },
          {
           
            key: 'action',
            render: (_, record) => (
              <div></div>
            ),
          },
      ];
const dispatch = useDispatch();
useEffect(() => {
  dispatch(LoadExamResultsAction(match.params.id));
  dispatch(LoadProfileAction());
  return () => {

  };
}, []);

const resultResponse = useSelector((state) => state.examResults.examResults);



  return (
    <div
      style={{
        padding: "20px",
      }}
    >
        <Button className="bookread_btn" onClick={() => history.goBack()}>Back</Button>
      {resultResponse !== "" &&
      resultResponse !== null &&
      resultResponse.success === true ? (
        <div>
          <Title level={3}>{resultResponse.exam_details.name} - {resultResponse.exam_details.obtained_marks}/{resultResponse.exam_details.total_marks}</Title>


          
          <Tabs defaultActiveKey="0">
            
          {resultResponse.exam_details.subject_details.map((subject, val) => ( 
            <TabPane tab={subject.subject_name} key={val}>
                <div>
              
                {subject.sections.map((section, val_s) => (
                    <div>
                           <Table columns={columns}
    rowKey={record => record.id} expandable={{
      expandedRowRender: record => <p style={{ margin: 0 }}>

        <ul >
            {record.mcqOptions.map((option, val_o) => ( <div>
                    <li ><div dangerouslySetInnerHTML={{ __html: option }} /></li></div>
            ))}
            
        </ul>
        <Title level={5}>Explanation</Title>   
        {record.explanation}
        
        </p>,
      rowExpandable: record => record.name !== 'Not Expandable',
    }} dataSource={section.questions} /> </div>
                ))}
                   
                
                </div>
            </TabPane>
        ))}
  
  </Tabs>

        </div>
        
      ) : (
        <div className="spin_pageload">
        <Spin />
      </div>
      )}

    </div>
  );
}
export default ExamResultsComponent;
