import HttpService from "./HttpService";
export const LoadBatches = () => {
  const http = new HttpService();
  let batchesUrl = "users/batches";
  const tokenId = "user-token";
  return http
    .getData(batchesUrl, tokenId)
    .then((data) => {
      // console.log(data);
      return data;
    })
    .catch((error) => {
      // console.log(error);
      return error;
    });
};
