export const RESTART_AUTH_RESPONSE = "RESTART_AUTH_RESPONSE";
export const LOADING = "LOADING";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";
export const CODE_ERROR = "CODE_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const LOAD_PROFILE_SUCCESS = "LOAD_PROFILE_SUCCESS";
export const LOAD_PROFILE_ERROR = "LOAD_PROFILE_ERROR";
export const LOAD_BATCHES_SUCCESS = "LOAD_BATCHES_SUCCESS";
export const LOAD_BATCHES_ERROR = "LOAD_BATCHES_ERROR";
export const LOAD_SINGLEBATCH_SUCCESS = "LOAD_SINGLEBATCH_SUCCESS";
export const LOAD_SINGLEBATCH_ERROR = "LOAD_SINGLEBATCH_ERROR";
export const LOAD_EXAMRESULTS_SUCCESS = "LOAD_EXAMRESULTS_SUCCESS";
export const LOAD_EXAMRESULTS_ERROR = "LOAD_EXAMRESULTS_ERROR";
