import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Profile from "./components/pages/ProfileComponent";
import Dashboard from "./components/pages/Student/DashboardComponent";
import Courses from "./components/pages/Student/CoursesComponent";
import Books from "./components/pages/Student/BooksComponent";
import BookReading from "./components/pages/Student/BookReadingComponent";
import ExamResults from "./components/pages/Student/ExamResultsComponent";

import Support from "./components/pages/Student/CoursesComponent";
import RMIndex from "./components/pages/Student/RMIndexComponent";
import Settings from "./components/pages/Student/SettingsComponent";

export default function PrivateRoute(props) {




  
  return (
    <div>
      {/*<Header/>*/}

      <Switch>
        <Route
          exact
          path={`${props.match.path}/view-profile/`}
          component={Profile}
        />
        <Route
          exact
          path={props.match.path}
          render={(props) => (
            <Redirect to={{ pathname: `${props.match.path}/view-profile/` }} />
          )}
        />
        <Route
          exact
          path={`${props.match.path}/view-profile/`}
          component={Profile}
        />
        <Route exact path="/user/courses/" component={Courses} />
        <Route path="/user/course/:id" component={Books} />

        {/* <Route path="/user/exam_result/:id" component={ExamResults} /> */}

        <Route
          exact
          path="/user/book_reading/:batch_id/:book_id"
          component={BookReading}
        />
        {/* <Route  path='/user/courses:courseId' component={Books}/> */}
        <Route
          exact
          path={`${props.match.path}/dashboard/`}
          component={Dashboard}
        />
        <Route exact path={`${props.match.path}/courses/`} component={Courses} />
        {/* <Route path="/courses/:id" component={Books} /> */}

        <Route exact path={`${props.match.path}/support`} component={Support} />
        <Route exact path={`${props.match.path}/exam_results/:id`} component={ExamResults} />

        <Route
          exact
          path={`${props.match.path}/rm-index`}
          component={RMIndex}
        />
        <Route
          exact
          path={`${props.match.path}/settings`}
          component={Settings}
        />
      </Switch>
    </div>
  );
}
