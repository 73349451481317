import { LoadSingleBatch } from "../../services/SingleBatchService";
import * as ActionTypes from "../ActionTypes";
export const LoadSingleBatchAction = (id) => {
  return (dispatch) => {
    console.log("yes");
    dispatch({ type: ActionTypes.LOADING });
    LoadSingleBatch({ id }).then(
      (res) => {
        // console.log(res);
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: ActionTypes.LOAD_SINGLEBATCH_SUCCESS, res });
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: ActionTypes.LOAD_SINGLEBATCH_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};
