import HttpService from "./HttpService";
import { notification } from "antd";

export const RegisterUserService = (credentials) => {
  const http = new HttpService();
  let signupUrl = "users/register";
  return http
    .postData(credentials, signupUrl)
    .then((data) => {
      console.log(data);
      if (data.success === false) {
        notification["error"]({
          message: "Error",
          description: data.message,
        });
      } else {
        notification["success"]({
          message: "Welcome to ReadMark",
          description: data.message,
        });
      }
      return data;
    })
    .catch((error) => {
      return error;
    });
};
export const LoginUserService = (credentials) => {
  const http = new HttpService();
  let loginUrl = "users/login";

if(credentials.token !== null || credentials.token !== ''){
  return http
  .postDataToken(credentials, loginUrl,credentials.token)
  .then((data) => {
    console.log(data);
    if (data.success === false) {
      notification["error"]({
        message: "Error",
        description: data.message,
      });
      localStorage.setItem("user-token", '');
    } else {
      notification["success"]({
        message: "Welcome to ReadMark",
        description: data.message,
      });
    }

    return data;
  })
  .catch((error) => {
    return error;
  });
}
else{
  return http
    .postData(credentials, loginUrl)
    .then((data) => {
      console.log(data);
      if (data.success === false) {
        notification["error"]({
          message: "Error",
          description: data.message,
        });
      } else {
        notification["success"]({
          message: "Welcome to ReadMark",
          description: data.message,
        });
      }

      return data;
    })
    .catch((error) => {
      return error;
    });
}

  
};
export const LogOutUserService = () => {
  const http = new HttpService();
  let loginUrl = "users/logout";
  const tokenId = "user-token";
  return http
    .getData(loginUrl, tokenId)
    .then((data) => {
      console.log(data);
      notification["success"]({
        message: "Welcome txo ReadMark",
        description: data.message,
      });
      localStorage.removeItem("user-token");
      return data;
    })
    .catch((error) => {
      return error;
    });
};
