import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { LoginAction } from "../../redux/actions/AuthActions";
import { useHistory } from "react-router-dom";


function UserLoginComponent() {
  const history = useHistory();
  //console.log(history);
  const dispatch = useDispatch();
  //const authResponse = useSelector(state=>state.userAuth.authResponse);
  const [fields, setState] = useState({
    email: "",
    password: "",
    token:window.location.search.replace('?','')
  });
  localStorage.setItem("user-token", window.location.search.replace('?',''));
  useEffect(() => {
    dispatch(LoginAction(fields, history));
    return () => {
      console.log('hi');
    };
  }, []);
  return (
   <div>Success</div>
  );
}

export default UserLoginComponent;


