import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  Spin
} from "antd";
import { Link } from "react-router-dom";
import { LoadSingleBatchAction } from "../../../redux/actions/SingleBatchAction";
import { useDispatch, useSelector } from "react-redux";
import {
  CalendarOutlined,BookOutlined,TrophyOutlined
} from '@ant-design/icons';
import { LoadProfileAction } from "../../../redux/actions/ProfileActions";
import { useHistory } from "react-router-dom";
const { Title } = Typography;
const SingleBatch = ({ match }) => {
  let history = useHistory();
  // const [data, setData] = useState([]);

  // const openExamWindow = (id) => {
  //   alert(id);
  //   // window.open('https://exams.edmark.in:8443/edmark/?exam_id="', 'sharer', 'toolbar=0,status=0,width=548,height=325');
  // };
  useEffect(() => {
    dispatch(LoadProfileAction());

    return () => {};
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LoadSingleBatchAction(match.params.id));
    return () => {};
  }, []);

  const batchResponse = useSelector(
    (state) => state.userSingleBatch.userSingleBatch
  );

  return (
    <div
      style={{
        padding: "20px",
      }}
    >
        <Button className="bookread_btn" onClick={() => history.goBack()}>Back</Button>
      {batchResponse !== "" &&
      batchResponse !== null &&
      batchResponse.success === true ? (
        <div>
          {batchResponse.singlebatch.map((batch) => (
            <Row gutter="32">
              <Col xs={24} sm={24} md={24} lg={16}>
                <Title level={3} style={{ color: "#f6921e" }}>
                  {batch.name}
                </Title>
                <div dangerouslySetInnerHTML={{ __html: batch.description }} />
              </Col>
              <Col xs={24} sm={24} md={24} lg={8}>
                <img alt="example" width={"100%"} max-height={"250px"} src={batch.image} />
              </Col>

            
              <Col span="24" style={{}}>
                
                <Row gutter={25} style={{marginTop:"30px"}}>
                {batch.resources.map((resource) => (
                <Col xs={24} sm={24} md={24} lg={6} style={{ margin: "10px;", marginBottom:"30px" }}>
                  <Card
                    hoverable
                    style={{
                      width: "100%",
                      background: "#FFF5EA",
                      borderRadius: "10px",
                    }}
                  >
                    <Title level={5}>
                      <b>{resource.name}</b>
                      
                    </Title>
                    <p><CalendarOutlined /> {resource.created_at}</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: resource.description.substring(0, 100),
                      }}
                    />

                        <Row>
                          <Col span={11}>
                          <Button className="bookread_btn" block shape="round">
                            <Link
                              to={`/user/book_reading/${batch.id}/${resource.id}`}
                            >
                            <BookOutlined /> Read Book
                            </Link>
                          </Button>
                          </Col>
                          <Col span={2}></Col>
                          <Col span={11}>
                           
                            {resource.exam_id !== null ?<div>
                            {resource.exam_screen === 0 ? <div> 
                              
                              <Button block  shape="round"><Link to={`/user/exam_results/${resource.exam_id}`}><TrophyOutlined /> View Result</Link> </Button> </div>
                                
                                :
                                <div> 
                              
                                  <Button block  shape="round"><a target={`_blank`} href={`https://exams.edmark.in:8443/edmark?exam_id=${resource.exam_id}&token=${localStorage.getItem("user-token")}`}><TrophyOutlined /> Take Quiz</a> </Button></div>
                                }
                            </div>
                           :<div></div>}
                            </Col>
                        </Row>



                   
                    
                    
                    {/* <Button className='primary_button'>Read More</Button> */}
                  </Card>
                </Col>
              ))}
                </Row>
              </Col>
            </Row>
          ))}
        </div>
      ) : (
        <div className="spin_pageload">
        <Spin />
      </div>
      )}

    </div>
  );
};
export default SingleBatch;
