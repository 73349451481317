import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  homeRoot: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  containerDiv: {
    flex: "auto",
    position: "fixed",
    top: "40%",
    left: "42.5%",
  },
  topFullDiv: {
    flex: "auto",
    position: "fixed",
    top: "22%",
    left: "0%",
    width: "100%",
  },
  extraBtnStyle: {
    margin: "2%",
  },
  authCard: {
    padding: "30px",
    textAlign: "center",
  },
  centerItem: {
    flex: "auto",
    top: "15%",
    position: "relative",
    left: "25%",
    right: "25%",
    width: "50%",

    marginBottom: "50px",
    marginTop: "90px",
  },
  fullWidth: {
    width: "90%",
    marginBottom: "25px",
    marginTop: "20px",
  },
  linkContainer: {
    marginBottom: "20px",
  },
  authResponse: {
    fontWeight: "bolder",
  },
  title: {
    flexGrow: 1,
  },
  avatarProfile: {
    width: "100px !important",
    height: "100px !important",
    color: "#fff !important",
    fontSize: "50px !important",
    backgroundColor: "#f6921e",
    textAlign: "center !important",
  },
  fullWidthProfile: {
    width: "80%",
    marginRight: "10%",
    marginLeft: "10%",
    marginTop: "80px",
  },
  textCenter: {
    textAlign: "center",
  },
  profileCard: {
    padding: "20px",
  },
  link: {
    color: "white",
    textDecoration: "none",
  },
  header: {
    backgroundColor: "#ffffff !important",
    color: "#000000 !important",
    paddingRight: "79px",
    paddingLeft: "118px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    fontWeight: 600,
    color: "#FFFEFE",
    textAlign: "left",
    float: "right",
    margin: "16px 0 16px 24px",
  },
  menuButton: {
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: "20px 30px",
  },

}));
