import React, { useEffect } from "react";
import {  Row, Col, Typography, Avatar,Tabs } from "antd";

import { useStyles } from "../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { LoadProfileAction } from "../../redux/actions/ProfileActions";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
const { Title } = Typography;
const { TabPane } = Tabs;

export default function ProfileView() {
  const dispatch = useDispatch();
  function Item(props) {
    const { sx, ...other } = props;
    return <Box {...other} />;
  }

  Item.propTypes = {
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
      ),
      PropTypes.func,
      PropTypes.object,
    ]),
  };
  const profileResponse = useSelector((state) => state.userDetails.userProfile);
  useEffect(() => {
    dispatch(LoadProfileAction());
    return () => {};
  }, []);
  return (
    <div>
      {profileResponse !== "" &&
      profileResponse !== null &&
      profileResponse.success === true ? (
        <div style={{
          padding: "20px",
        }}>
          <Row >
            <Col span="24" >
            
                <Tabs defaultActiveKey="1" >
      <TabPane
        tab="Profile"
        key="1"
        style={{
          height: 200,
        }}
      >
        <Avatar
                        size={150}
                        src="https://joeschmoe.io/api/v1/random"
                      />
                      <Title level={5}> {profileResponse.data.fullName}</Title>
                <p> {profileResponse.data.email}</p>
                <p>{profileResponse.data.student_code}</p>
                <p>{profileResponse.data.phone}</p>
      </TabPane>
      {/* <TabPane tab="Payments" key="2">
        Content of Tab Pane 2
      </TabPane>
      <TabPane tab="Support" key="3">
        Content of Tab Pane 3
      </TabPane>
      <TabPane tab="Refer A Friend" key="4">
        Content of Tab Pane 3
      </TabPane> */}
    </Tabs>

            </Col>
          </Row>
          {/* <Grid container spacing={2}>
            <Grid item xs={3}>
              <Item className={classes.textCenter}>
                <Avatar
                  className={classes.avatarProfile}
                  alt={profileResponse.data.fullName}
                  src="/static/images/avatar/1.jpg"
                  width="50px"
                />
                <h4> {profileResponse.data.fullName}</h4>
                <p> {profileResponse.data.email}</p>
                <p>{profileResponse.data.created_at}</p>
              </Item>
            </Grid>
            <Grid item xs={9}>
              <Item>xs=8</Item>
            </Grid>
          </Grid> */}
        </div>
      ) : (
        <div>Unable to display profile</div>
      )}
    </div>
  );
}
