import './App.css';
import {BrowserRouter} from 'react-router-dom';
import Routes from "./Routes";
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import 'antd/dist/antd.min.css';
function App() {
return (
<BrowserRouter>
<div className="App">
<Routes/>
</div>
</BrowserRouter>
);
}
export default App;